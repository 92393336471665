<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>档案库</a-breadcrumb-item>
        <a-breadcrumb-item>渠道组织管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <div class="left__content">
        <a-col :span="12">
          <div class="menu__search">
            <span>机构名称</span>
            <a-input v-model="searchName" style="width: 50%;"></a-input>
            <a-button type="primary" class="search-button" @click="onSearch">
              搜索
            </a-button>
          </div>
          <a-tree
            style="margin-top: 12px"
            :tree-data="treeNode"
            :replace-fields="areaTreeFields"
            @select="titleClick"
          />
        </a-col>
      </div>
      <div class="right__content">
        <a-col :span="24">
          <a-row>
            <a-col :span="12">
              <a-form-item label="基本信息" class="special__item">
                <span></span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="机构名称">
                <div>{{ info.corporationName }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="合作状态">
                <div>{{ info.incorporateStatus }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="统一社会信用代码">
                <div>{{ info.corporationCode }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="机构类型">
                <div>{{ reportType }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="联系人姓名">
                <div>{{ info.contactName }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="联系人电话">
                <div>{{ info.contactMobile }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="联系地址">
                <div>
                  {{
                    info.registeredAreaName
                      ? replace(info.registeredAreaName) +
                        info.registeredDetailAddress
                      : ""
                  }}
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="经营地址">
                <div>
                  {{
                    info.executorFullAreaName ? info.executorFullAreaName : ""
                  }}
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="reportType === '渠道终端'">
            <a-form-item label="经营地址经纬度">
              <div class="amap-wrapper">
                <el-amap class="amap-box" vid="campMap" :plugin="mapPlugin">
                  <el-amap-marker
                    v-if="campLocation"
                    :position="campLocation"
                  ></el-amap-marker>
                </el-amap>
              </div>
            </a-form-item>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="认证人身份">
                <div>{{ info.authType }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="法人姓名">
                <div>{{ info.legalRepresentativeName }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="法人身份证">
                <div>{{ info.legalRepresentativeIdNo }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="法人性别">
                <div>{{ info.legalRepresentativeGender }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="法人人脸认证">
                <div v-if="corporationId">
                  {{ info.legalRepresentativeAuthFlag ? "是" : "否" }}
                </div>
                <div v-else>--</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="经营人姓名">
                <div>{{ info.executorName ? info.executorName : "--" }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="经营人身份证">
                <div>{{ info.executorIdNo ? info.executorIdNo : "--" }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="经营人性别">
                <div>
                  {{ info.executorGender ? info.executorGender : "--" }}
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="经营人人脸认证">
                <div v-if="corporationId">
                  {{ info.executorAuthFlag ? "是" : "否" }}
                </div>
                <div v-else>--</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item label="营业执照">
                <div v-viewer>
                  <img
                    :data-source="imageBaseUrl + info.businessLicenseUrl"
                    style="width: 80%"
                    :src="imageBaseUrl + info.businessLicenseUrl"
                    :alt="info.businessLicenseName"
                  />
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="开票信息" class="special__item">
                <span></span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="单位名称">
                <div>{{ info.corporationName }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="纳税人识别号">
                <div>{{ info.corporationCode }}</div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="注册地址区域">
                <span>
                  {{ info.invoiceAreaName }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="注册地址详情">
                <span>
                  {{ info.invoiceDetailAddress }}
                </span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="注册电话">
                <span>
                  {{ info.invoiceTel }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="开户银行名称">
                <span>
                  {{ info.invoiceBankName }}
                </span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="开户银行账号">
                <span>
                  {{ info.invoiceBankAccount }}
                </span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider />
          <a-row>
            <a-col v-if="type === 'endConsumerMarket'" :span="12">
              <a-form-item label="所在服务中心">
                <span>{{ info.serviceCentreName }}</span>
              </a-form-item>
            </a-col>
            <a-col
              v-if="
                type === 'serviceCenter' ||
                  type === 'warehouse' ||
                  type === '渠道终端'
              "
              :span="12"
            >
              <a-form-item label="所在运营中心">
                <span>{{ info.operatingCenterName }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col
              v-if="
                type === 'operationCenter' || type === 'operationHeadquarters'
              "
              :span="12"
            >
              <a-form-item label="管辖区域">
                <span>
                  {{
                    administerAreaName && administerAreaName.length > 0
                      ? administerAreaName
                      : "暂无信息"
                  }}
                </span>
              </a-form-item>
            </a-col>
            <a-col v-if="contractVO" :span="12">
              <a-form-item label="合作起止时间">
                <span>
                  {{
                    contractVO && contractVO.incorporateStartDate
                      ? dtFormat(contractVO.incorporateStartDate)
                      : "暂无信息"
                  }}
                  ~
                  {{
                    contractVO && contractVO.incorporateEndDate
                      ? dtFormat(contractVO.incorporateEndDate)
                      : "暂无信息"
                  }}
                </span>
              </a-form-item>
            </a-col>
          </a-row>
          <div v-if="type === 'serviceCenter'">
            <a-row
              v-for="(item, index) in info.warehouseBindContractVOList"
              :key="index"
            >
              <a-col :span="12">
                <a-form-item label="绑定货栈">
                  {{ item.corporationName ? item.corporationName : "--" }}
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="合同起止时间">
                  <span>
                    {{
                      item && item.incorporateStartDate
                        ? dtFormat(item.incorporateStartDate)
                        : "暂无信息"
                    }}
                    ~
                    {{
                      item && item.incorporateEndDate
                        ? dtFormat(item.incorporateEndDate)
                        : "暂无信息"
                    }}
                  </span>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <a-row v-if="type === 'operationCenter'">
            <a-col :span="12">
              <a-form-item label="任务完成率要求">
                <span>{{ info.taskCompletionRate }}%</span>
              </a-form-item>
              <a-form-item label="销售完成率要求">
                <span>{{ info.salesCompletionRate }}%</span>
              </a-form-item>
            </a-col>
            <a-col :span="12"></a-col>
          </a-row>
          <a-row v-if="type === 'operationCenter'">
            <a-col :span="16">
              <a-form-item label="销售量要求">
                <a-table
                  :data-source="tblData"
                  :columns="showSalesColumns"
                  :pagination="false"
                  :loading="loading"
                  row-key="id"
                ></a-table>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="type === 'factory'">
            <a-form-item label="产品图片">
              <div
                v-for="(item, index) in info.factoryProductsPictureFiles"
                :key="index"
                v-viewer
              >
                <img
                  :data-source="imageBaseUrl + item.fileUrl"
                  style="width: 30%;margin-right: 3%"
                  :src="imageBaseUrl + item.fileUrl"
                  :alt="item.fileName"
                />
              </div>
            </a-form-item>
          </a-row>
          <a-row v-if="type === 'factory'">
            <a-form-item label="3C证书">
              <div
                v-for="(item, index) in info.certificate3cFiles"
                :key="index"
                v-viewer
              >
                <img
                  :data-source="imageBaseUrl + item.fileUrl"
                  style="width: 30%;margin-right: 3%"
                  :src="imageBaseUrl + item.fileUrl"
                  :alt="item.fileName"
                />
              </div>
            </a-form-item>
          </a-row>
          <a-row>
            <a-col v-if="checkCooperationUrl" :span="24">
              <a-form-item label="加盟协议">
                <a :href="imageBaseUrl + checkCooperationUrl">
                  {{ checkCooperationName }}
                </a>
              </a-form-item>
            </a-col>
          </a-row>
          <div class="footer">
            <a-button type="primary" @click="toModify" v-if="corporationId">
              修改
            </a-button>

            <a-popconfirm
              v-if="info.isDeleteAllow"
              title="确认是否删除?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onDelete"
            >
              <a-button>删除</a-button>
            </a-popconfirm>
          </div>
        </a-col>
      </div>
    </section>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { toChinesNum } from "@/components/utils";
import { imageBaseUrl } from "@/services/HttpService";
import {
  deleteArchives,
  fetchAllServiceCenter,
  fetchArchivesDetail,
  fetchOrganizationManagementTree
} from "@/services/Archives";

const showSalesColumns = [
  {
    title: "月份",
    dataIndex: "salesMonth",
    width: "50%"
  },
  {
    title: "销售数量",
    dataIndex: "salesVolume",

    width: "50%"
  }
];
export default {
  data() {
    return {
      toChinesNum,
      imageBaseUrl,
      tsFormat,
      dtFormat,
      loading: false,
      tree: {},
      areaTreeFields: {
        title: "corporationName",
        value: "distributorType",
        key: "distributorId",
        children: "nextNode"
      },
      testNode: [],
      treeNode: [],
      contentHeight: 0,
      contractVO: {},
      tblData: [],
      showSalesColumns,
      searchName: "",
      checkCooperationUrl: "",
      checkCooperationName: "",
      current: [],
      serviceCenterData: [],
      administerAreaName: [],
      administerAreaCode: [],
      info: {},
      openKeys: [],
      reportType: "",
      type: "",
      isfirst: true,
      corporationId: "",
      editReceiveForm: this.$form.createForm(this),
      //地图插件
      mapPlugin: ["ToolBar"],
      campLocation: []
    };
  },
  created() {},
  mounted() {
    this.onLoadServiceCenter();
    this.fetchManagementTree();
    if (this.$refs.content) {
      this.contentHeight = this.$refs.content.offsetHeight;
    }
  },
  methods: {
    replace(value) {
      return (value = value.replace(/\s*/g, ""));
    },
    // 获取档案详情
    onDetail() {
      if (this.type) {
        if (this.type === "warehouse") {
          this.reportType = "货栈";
        }
        if (this.type === "operationCenter") {
          this.reportType = "运营中心";
        }
        if (this.type === "operationHeadquarters") {
          this.reportType = "运营总部";
        }
        if (this.type === "serviceCenter") {
          this.reportType = "服务中心";
        }
        if (this.type === "endConsumerMarket") {
          this.reportType = "渠道终端";
        }
        if (this.type === "factory") {
          this.reportType = "制造原厂";
        }
      }
      fetchArchivesDetail(this.type, this.distributorId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.info = resp.data.data;
          // 渠道终端 回显经营地址 经纬度
          if (data.longitude && data.latitude) {
            this.campLocation = [data.longitude, data.latitude];
          }
          if (this.info.registeredAreaName) {
            this.info.registeredAreaName = this.info.registeredAreaName.trim();
          }
          if (
            this.info.tripartiteAgreementVOList &&
            this.info.tripartiteAgreementVOList.length > 0
          ) {
            this.contractVO = this.info.tripartiteAgreementVOList[0].contractVO;
          } else {
            this.contractVO = this.info.contractVO;
          }
          this.tblData = this.info.centerSalesVolumeVOList;
          if (this.contractVO && this.contractVO.cooperationAgreementFile) {
            this.checkCooperationUrl = this.contractVO.cooperationAgreementFile.fileUrl;
            this.checkCooperationName = this.contractVO.cooperationAgreementFile.fileName;
          }
          if (this.info.cooperationAgreementFile) {
            this.checkCooperationUrl = this.info.cooperationAgreementFile.fileUrl;
            this.checkCooperationName = this.info.cooperationAgreementFile.fileName;
          }
          if (
            this.info.jurisdictionAreaCodeMap &&
            this.info.jurisdictionAreaCodeMap.length > 0
          ) {
            this.info.jurisdictionAreaCodeMap.forEach(item => {
              this.administerAreaName.push(Object.values(item));
              this.administerAreaCode.push(Object.keys(item));
            });
            this.administerAreaCode = [].concat.apply(
              [],
              this.administerAreaCode
            );
            this.administerAreaName = [].concat
              .apply([], this.administerAreaName)
              .toString();
          } else {
            this.administerAreaName = [];
          }
          this.editReceiveForm.setFieldsValue({ ...data });
          this.editReceiveForm.setFieldsValue({
            jurisdictionAreaCodeList: this.administerAreaCode
          });
          this.areaCode = [
            data.registeredProvinceCode,
            data.registeredCityCode,
            data.registeredCountyCode
          ];
          this.executorAreaCode = [
            data.executorProvinceCode,
            data.executorCityCode,
            data.executorCountyCode
          ];
          if (data.cooperationAgreementFile) {
            const params = {
              uid: 1,
              fileUrl: data.cooperationAgreementFile.fileUrl,
              name: data.cooperationAgreementFile.fileName,
              status: "done",
              url: imageBaseUrl + data.cooperationAgreementFile.fileUrl
            };
            this.editReceiveForm.setFieldsValue({
              cooperationAgreement: params
            });
            this.fileList.push(params);
            const reportParams = {
              fileUrl: data.cooperationAgreementFile.fileUrl,
              fileName: data.cooperationAgreementFile.fileName
            };
            this.reportFileList = reportParams;
          }
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 获取服务中心列表
    onLoadServiceCenter() {
      fetchAllServiceCenter().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.serviceCenterData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 搜索具体机构
    onSearch() {
      this.fetchManagementTree();
    },
    // 获取组织树
    fetchManagementTree() {
      fetchOrganizationManagementTree().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.tree = resp.data.data;
          this.treeNode = resp.data.data.nextNode;
          const searchList = [];
          if (this.searchName) {
            this.treeNode.forEach(item => {
              if (item.corporationName.includes(this.searchName.trim())) {
                searchList.push(item);
              } else if (item.nextNode && item.nextNode.length > 0) {
                item.nextNode.forEach(items => {
                  if (items.corporationName.includes(this.searchName.trim())) {
                    searchList.push(items);
                  } else if (items.nextNode && items.nextNode.length > 0) {
                    items.nextNode.forEach(itemss => {
                      if (
                        itemss.corporationName.includes(this.searchName.trim())
                      ) {
                        searchList.push(itemss);
                      } else if (
                        itemss.nextNode &&
                        itemss.nextNode.length > 0
                      ) {
                        itemss.nextNode.forEach(itemsss => {
                          if (
                            itemsss.corporationName.includes(
                              this.searchName.trim()
                            )
                          ) {
                            searchList.push(itemsss);
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
            this.treeNode = searchList;
          }
          if (this.isfirst) {
            this.chooserItem(this.treeNode[0]);
            this.isfirst = false;
          }
        }
      });
    },
    chooserItem(item) {
      this.type = item.distributorType;
      this.corporationId = item.corporationId;
      this.distributorId = item.distributorId;
      this.onDetail();
    },
    // 返回类型
    chooseType() {
      return;
    },
    // 二级menu点击
    titleClick(selectedKey) {
      if (selectedKey.length > 0) {
        let distributorType;
        this.treeNode.forEach(item => {
          if (item.distributorId === selectedKey[0]) {
            distributorType = item.distributorType;
          } else {
            if (item.nextNode && item.nextNode.length > 0) {
              item.nextNode.forEach(items => {
                if (items.distributorId === selectedKey[0]) {
                  distributorType = items.distributorType;
                } else {
                  if (items.nextNode && items.nextNode.length > 0) {
                    items.nextNode.forEach(itemss => {
                      if (itemss.distributorId === selectedKey[0]) {
                        distributorType = itemss.distributorType;
                      } else {
                        if (itemss.nextNode && itemss.nextNode.length > 0) {
                          itemss.nextNode.forEach(itemsss => {
                            if (itemsss.distributorId === selectedKey[0]) {
                              distributorType = itemsss.distributorType;
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
        this.type = distributorType;
        this.distributorId = selectedKey[0];
        this.onDetail();
      }
    },
    onDelete() {
      deleteArchives(this.distributorId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.info = {};
          this.searchName = "";
          this.fetchManagementTree();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 前往修改
    toModify() {
      let type = "";
      if (this.type) {
        if (this.type === "warehouse") {
          type = "货栈";
        }
        if (this.type === "operationCenter") {
          type = "运营中心";
        }
        if (this.type === "operationHeadquarters") {
          type = "运营总部";
        }
        if (this.type === "serviceCenter") {
          type = "服务中心";
        }
        if (this.type === "endConsumerMarket") {
          type = "渠道终端";
        }
        if (this.type === "factory") {
          type = "制造原厂";
        }
      } else {
        return;
      }
      this.$router.push({
        name: "ModifyArchives",
        params: {
          type: type,
          action: "组织管理修改",
          id: this.corporationId
        },
        query: {
          distributorId: this.distributorId
        }
      });
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  margin: 10px 20px 0 20px;
  background-color: transparent;
  display: flex;
  width: 100%;
}
.left__content,
.right__content {
  padding: 25px 30px 25px 30px;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}
.right__content {
  flex: 1;
  margin-left: 20px;
}
/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 137px !important;
}

.special__item /deep/ .ant-form-item-label > label {
  color: #101010;
  font-size: 14px;
  font-weight: bold;
}

.special__item /deep/ .ant-form-item-label > label::after {
  content: "";
  width: 4px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer button {
  margin-right: 8px;
}

.menu__search {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/deep/.ant-form-item .ant-form-item-control-wrapper {
  overflow: auto;
}
/deep/.ant-tree li {
  padding: 5px 0;
}
/deep/.ant-col-12 {
  width: 45%;
}
/deep/.ant-row .ant-col-12:first-child {
  margin-right: 10%;
}
.amap-wrapper {
  width: 350px;
  height: 350px;
}
</style>
